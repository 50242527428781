import {
  createStylesParams,
  StyleParamType,
  type IStyleParam,
} from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import * as LEGACYSTYLEPARAMS from './legacyStyleParams';

export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  return {
    showPostCount: styleParams.booleans.showPostCountV2 ? 'inline' : 'none',
    showAllPostCategory: styleParams.booleans.showAllPostsCategoryV2
      ? 'flex'
      : 'none',
    showEmptyMessage: styleParams.booleans.showAllPostsCategoryV2
      ? 'none'
      : 'flex',
  };
};

type GetStyleParamType = {
  isMobile: boolean;
  getStyleParamValue: (param: IStyleParam) => any;
  paramName: IStyleParam;
  mobileParamName: IStyleParam;
  dependencyParam: IStyleParam;
};

const resolveDefaultStyleParamValue = ({
  isMobile,
  getStyleParamValue,
  paramName,
  mobileParamName,
  dependencyParam,
}: GetStyleParamType) => {
  if (isMobile) {
    const isMobileSettingsEnabled = getStyleParamValue(dependencyParam);
    if (isMobileSettingsEnabled) {
      const mobileParamValue = getStyleParamValue(mobileParamName);
      if (typeof mobileParamValue !== 'undefined') {
        return mobileParamValue;
      }
    }
  }
  return getStyleParamValue(paramName);
};

export default createStylesParams<{
  showAllPostsCategoryV2: StyleParamType.Boolean;
  showPostCountV2: StyleParamType.Boolean;
  backgroundColorHoverV2: StyleParamType.Color;
  textColorHoverV2: StyleParamType.Color;
  cornerRadiusV2: StyleParamType.Number;
  borderWidthV2: StyleParamType.Number;
  borderColorV2: StyleParamType.Color;
  separatorWidthV2: StyleParamType.Number;
  separatorColorV2: StyleParamType.Color;
  backgroundColorV2: StyleParamType.Color;
  textFontV2: StyleParamType.Font;
  textColorV2: StyleParamType.Color;
  textAlignmentV2: StyleParamType.Number;
  textPaddingHorizontalV2: StyleParamType.Number;
  textPaddingVerticalV2: StyleParamType.Number;
}>({
  showAllPostsCategoryV2: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showAllPostsCategory,
        mobileParamName: LEGACYSTYLEPARAMS.showAllPostsCategoryMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showPostCountV2: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostCount,
        mobileParamName: LEGACYSTYLEPARAMS.showPostCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  backgroundColorHoverV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.backgroundColorHover,
        mobileParamName: LEGACYSTYLEPARAMS.backgroundColorHoverMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textColorHoverV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textColorHover,
        mobileParamName: LEGACYSTYLEPARAMS.textColorHoverMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textFont,
        mobileParamName: LEGACYSTYLEPARAMS.textFontMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  cornerRadiusV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.cornerRadius,
        mobileParamName: LEGACYSTYLEPARAMS.cornerRadiusMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  borderWidthV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.borderWidth,
        mobileParamName: LEGACYSTYLEPARAMS.borderWidthMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  borderColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.borderColor,
        mobileParamName: LEGACYSTYLEPARAMS.borderColorMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  separatorWidthV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.separatorWidth,
        mobileParamName: LEGACYSTYLEPARAMS.separatorWidthMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  separatorColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.separatorColor,
        mobileParamName: LEGACYSTYLEPARAMS.separatorColorMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  backgroundColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.backgroundColor,
        mobileParamName: LEGACYSTYLEPARAMS.backgroundColorMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textColor,
        mobileParamName: LEGACYSTYLEPARAMS.textColorMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDesignSettingsEnabled,
      }),
  },
  textAlignmentV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textAlignment,
        mobileParamName: LEGACYSTYLEPARAMS.textAlignmentMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  textPaddingHorizontalV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textPaddingHorizontal,
        mobileParamName: LEGACYSTYLEPARAMS.textPaddingHorizontalMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
  textPaddingVerticalV2: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.textPaddingVertical,
        mobileParamName: LEGACYSTYLEPARAMS.textPaddingVerticalMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileLayoutSettingsEnabled,
      }),
  },
});
